// https://onokumus.com/metismenujs/mm-vertical.html
import MetisMenu from 'metismenujs';

import { constants, instances } from '../store/index.js';

class MainNavMobile {
    constructor() {
        this.dom = {};
        this.dom.el = document.querySelector('#main-menu-mobile');
        // this.dom.trigger = document.querySelector('.hamburger');
        
        // state
        /*
        this.state = {
            open: false
        }
        */

        // options and config of metis menu
        this.config = {
            toggle: false
        }
    }
    /*
    open = (e) => {
        e.preventDefault();
    }
    */

    addListeners() {
       
        document.addEventListener(
            "DOMContentLoaded", (e) => {
                new MetisMenu(this.dom.el, this.config);
            }
        );                  
    }

    init() { 
        this.addListeners();
    }
}

export default MainNavMobile;