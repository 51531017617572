// alert('hello');
// Test

import MainNavMobile from './components/mainnav_mobile.js';
import MainNavDesktop from './components/mainnav_desktop.js';
import MainNavHamburger from './components/mainnav_hamburger.js';
import GwtSwiper from './components/gwt_swiper.js';
import GwtTopbar from './components/gwt_topbar.js'; 
import { lightbox } from './components/gwt_lightbox.js';

import { constants, instances } from './store/index.js';

constants.isDevice = 'ontouchstart' in window;
constants.isDevice && document.body.classList.add('is-device');

// set vh css var
// document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

instances.hamburger = new MainNavHamburger();
// constants.hamburgerState = instances.hamburger.state; 
instances.hamburger.init();

// WORK THIS menuMobile Out as Accordion
instances.menuMobile = new MainNavMobile();
instances.menuMobile.init();

instances.menudesktop = new MainNavDesktop();
instances.menudesktop.init();

instances.gwtswiper = new GwtSwiper(); 
instances.gwtswiper.init();

instances.gwttopbar = new GwtTopbar(); 
instances.gwttopbar.init();

lightbox();
// TEST


/*
[...mnToggles].forEach(h => h.addEventListener('click', i => {
    console.log(i); 
}));

*/
/*
for (i = 0; i < mnToggles.length; ++i) {
    // mnToggles[i].classList.add('--active');
    mnToggles[i].addEventListener('click', test)
}

function test() {
    console.log('hello'); 
}

*/
/*
let mnToggles = document.querySelectorAll('a.mn-drawer-toggle');
const handleClick = (e) => {
    e.preventDefault();
    mnToggles.forEach(node => {
      node.classList.remove('active');
    });
    e.currentTarget.classList.add('active');
  
  }

  mnToggles.forEach(node => {
    node.addEventListener('click', handleClick)
  });
  */