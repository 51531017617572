export const constants = {
  isDevice: false
};
  
export const instances = {
  // scroll: undefined,
  menuMobile: undefined,
  menudesktop: undefined,
  hamburger: undefined,
  gwtswiper: undefined,
  gwttopbar: undefined
};
  