// GLightbox 
// see https://github.com/biati-digital/glightbox
// demo https://github.com/biati-digital/glightbox/blob/master/index.html

// import '/path/to/glightbox.js';
import GLightbox from 'glightbox/dist/js/glightbox.js';

var ligthboxEl = document.querySelectorAll('.gallery--lightbox');
// check if typeElemen exists
// var checkEl = (typeof(ligthboxEl) != 'undefined' && ligthboxEl != null) ? true : false;	


function init() {
	
	// set up lighbox 
	const lightbox = GLightbox({
		selector: '.glightbox'
    	// touchNavigation: true,
    	// loop: true,
    	// autoplayVideos: true
	});

	// trigger lightbox with svg icon (legend)
    /*
	const triggerLightbox = document.querySelector( '.svg-btn--gallery' );
	if ( triggerLightbox ) {
		triggerLightbox.addEventListener( 'click', function(e) {
			e.preventDefault();
			lightbox.openAt(0); 
		});
	}
    */
}

// export function 
const lightbox = function() {	 
   // wait until DOM is ready
    document.addEventListener('DOMContentLoaded', function(event) {
        
        // wait until window, stylesheets, images, links, and other media assets are loaded
        /*
        window.onload = function() {

             // All ready, start!
            if(checkEl) {
            	init();
            }

         };
        */

        /* 
        if(checkEl) {
        	init();
        }
        */
        init();
    });
};

export { lightbox };