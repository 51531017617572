import { constants, instances } from '../store/index.js';

class MainNavDesktop {
    constructor() {
        this.dom = {};
        this.dom.el = document.querySelector('.mainmenu[data-variant="menu-desktop"]');
		    this.dom.drawerToggle = this.dom.el.querySelectorAll('.drawer-toggle');
        this.dom.html = document.documentElement; 
        this.dom.dimm = document.querySelector('.gwt-dimm');

        this.state = {
            open: false,
            // scrollEnabled: false,
            // progress: 0,
        };
    }
    
    open = (e) => {
      let el = e.currentTarget; 
      e.preventDefault();

      if (el.classList.contains('is-active')) {
        // already open -> close
        this.close();
        this.state.open = false;

      } else {
        // open
        this.close(); // close all
        el.classList.add('is-active'); // open current
        el.parentNode.classList.add('is-open');
        this.state.open = true;
        this.dimmBackground();
      }

      this.siblings(el.parentNode);
      
    }

    close = (e) => {
      if (!this.state.open) return;

      // remove all
      [...this.dom.drawerToggle].forEach(node => {
        let el = node; 
        el.classList.remove('is-active');
        el.parentNode.classList.remove('is-open');
      });
      this.state.open = false;
      
      this.dimmBackground();
    }

    siblings = (elem) => {
      if(elem === null || typeof elem === 'undefined') {
        // Fallback: find the current "is-open drawer"
        elem = document.querySelector('.drawer.is-open');
      }
      let siblings = this.dom.el.querySelectorAll('.drawer'); 

      if (this.state.open) {
        for (var i = 0; i < siblings.length; i++) {
          if (siblings[i] !== elem) { // optional
            siblings[i].classList.add('is-closed');
          } else {
            siblings[i].classList.remove('is-closed');
          }
        }
      } else {
        for (var i = 0; i < siblings.length; i++) {
            siblings[i].classList.remove('is-closed');
        }
      } 
    }

    dimmBackground = () => {
      // dimm bg
      if (this.state.open) {
        this.dom.html.classList.add('mainmenu-desktop-open');
      } else {
        this.dom.html.classList.remove('mainmenu-desktop-open');
        this.siblings(null);
      }
    }
    
    /*
    drawer() {}
    */

    addListeners() {
      [...this.dom.drawerToggle].forEach(node => {
        node.addEventListener('click', this.open)
      });

     this.dom.dimm.addEventListener('click', this.close); 
    }

    init() {
        this.addListeners();
        // console.log(instances.menudesktop); 
    }
}

export default MainNavDesktop;
