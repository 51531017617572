// Ref: https://www.jeffastor.com/blog/building-a-dropdown-menu-in-10-lines-of-javascript

class GwtTopbar {
    constructor() {
        this.dom = {};
        this.dom.dropicons = document.querySelectorAll('.nav-icon-toggle'); // toggler
        // this.dom.dropdowns = document.querySelectorAll('.nav-icon-dropdown'); // menu 
    }

    toggleIcons = (e) => {
        let el = e.currentTarget; 
        e.preventDefault();

        if (el.classList.contains('is-active')) { 
            this.closeIcon(el);
        } else {
            [...this.dom.dropicons].forEach(node => {
                this.closeIcon(node);
            });
            this.openIcon(el); 
        }
    }

    openIcon = (elem) => {
        elem.classList.add('is-active') 
        elem.parentNode.classList.add('is-open');  
    }

    closeIcon = (elem) => {
        elem.classList.remove('is-active');
        elem.parentNode.classList.remove('is-open');
    }
   
    clickOutsideToggleIcon = (e) => {
        let clickedElem = e.target; // clicked element
        const drops = this.dom.dropicons;
        // if ( !clickedElem.classList.contains('nav-icon-dropdown') && !clickedElem.closest('.nav-icon-dropdown') ) {
        if (clickedElem.closest('.nav-icon-dropdown')) {
            // console.log('return'); 
           return;
        } else if (clickedElem.closest('.nav-icon-set')){
            // console.log('return nav-icon-set');
            return;
        } else {
            // do stuff => close (click is most likely outside)
            [...this.dom.dropicons].forEach(node => {
                this.closeIcon(node);
            });
        }
    }

    addListeners() {
        [...this.dom.dropicons].forEach(node => {
          node.addEventListener('click', this.toggleIcons)
        });
        document.addEventListener('click', this.clickOutsideToggleIcon)
    }

    init() {
        this.addListeners();
    }

}
export default GwtTopbar;
