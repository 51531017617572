import { constants, instances } from '../store/index.js';


class MainNavHamburger {
    constructor() {
        this.dom = {};
        this.dom.el = document.querySelector('.hamburger');
        this.dom.target = document.querySelector('#off-canvas-mainnav');
        // this.dom.body = document.body;
        this.dom.doc = document.documentElement; // html element

        this.state = {
            open: false
        }
    }

    /*
    open = (e) => {}
    close = (e) => {}
    */

    toggle = (e) => {
        let a = this.dom.el.getAttribute('aria-expanded');
        let b = this.dom.target.getAttribute('aria-hidden');
        let current_state; 

        // toggle classes & styles 
        this.dom.el.classList.toggle('is-active');
        this.dom.target.classList.toggle('is-active');
        this.dom.doc.classList.toggle('off-canvas-active');
        // this.dom.body.style.overflow = 'hidden';

        if (a == 'true') {
            a = 'false';
            b = 'true';
            current_state = false; 
            // this.state.open = false;
        } else {
            a = 'true';
            b = 'false';
            current_state = true; 
            // this.state.open = true;
        }
        // console.log(this.state.open); 
        this.dom.el.setAttribute('aria-expanded', a);
        this.dom.target.setAttribute('aria-hidden', b);
        this.state.open = current_state; 
    }

    addListeners() {
        this.dom.el.addEventListener('click', this.toggle);
    }

    init() {
        // console.log(this.state.open); 
        this.addListeners();
    }
}


export default MainNavHamburger;