import Swiper from 'swiper/swiper-bundle'; // '../../../node_modules/'
// 
/*
import Swiper, { Navigation, Scrollbar } from 'swiper/swiper-bundle';
modules: [ Navigation, Pagination ],
*/

// import styles bundle
// import 'swiper/css/bundle';
// import 'swiper/swiper-bundle.css';

/* const swiper = new Swiper(...); */

class GwtSwiper {
    // init Swiper:
    constructor() {
        this.dom = {};
        this.dom.baseswipers = document.querySelectorAll('.swiper-base');
        // this.dom.target = document.querySelector('#off-canvas-mainnav');

        this.config = {
            slidesPerView: 1, // mobile
            centeredSlides: false,
            spaceBetween: 16, // 1rem
            grabCursor: true,
            preventClicksPropagation: true,
            touchEventsTarget: 'container',
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            scrollbar: {
                el: ".swiper-scrollbar",
                hide: false,
                dragClass: 'gwt-swiper-scrollbar-drag',
                draggable: true,
                // snapOnRelease: false,
                dragSize: 'auto'
            },
            /*
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 16
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 16 // 1rem
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 32 // 2rem
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 48 // 3rem
                },
                1400: {
                    slidesPerView: 5,
                    spaceBetween: 48 // 3rem
                }
            }
            */
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 16
                },
                520: {
                    slidesPerView: 2,
                    spaceBetween: 16 // 1rem
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 48 // 2rem
                }
            }
        }
    }

    init() {
        /*
        [...this.dom.swipers].forEach(node, index => {
            // node.addEventListener('click', this.open)
            console.log(node, index);
        });
        */
        const swipers = this.dom.baseswipers;
        // console.log(swipers); 

        for( let i = 0; i < swipers.length; i++ ) {
  
            swipers[i].classList.add('swiper-container-' + i);
          
            var slider = new Swiper('.swiper-container-' + i, this.config);
        }
    }

}
export default GwtSwiper;
